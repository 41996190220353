import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import DelegateCard from "../components/DelegateCard"
import { useAxiosClient } from "../service/useAxiosClient"
import TitlePage from "../components/TitlePage"

export default function WhoToContact({ location }) {
  const [members, setMembers] = useState([])

  const [{ data: dataMembers }] = useAxiosClient({
    url: `/team-members/web/`,
    method: "GET",
  })

  useEffect(() => {
    if (dataMembers && Array.isArray(dataMembers)) {
      setMembers(dataMembers)
    }
  }, [dataMembers])

  const path = [
    { label: "Inicio", to: "/" },
    { label: "Contacto - ¿A quién dirigirme?" },
  ]

  const renderContacts = () => {
    return (
      !!members.length &&
      members.map(item => (
        <div className="col-12 col-md-3 p-0">
          <DelegateCard
            image={
              item?.is_man
                ? "/imgs/delegates/delegate-default.jpg"
                : "/imgs/delegates/silueta-mujer.jpg"
            }
            email={item?.appointment}
            employment={item?.name}
            className="WhoToContact-contact"
          />
        </div>
      ))
    )
  }

  return (
    <Layout>
      <TitlePage title="Equipo de EFPA España" url={location.pathname} />
      <Hero image="/imgs/contact-hero.jpg" text="Contacto" />
      <div className="container950">
        <div className="WhoToContact">
          <Breadcrumb path={path} />
          <TitleBlock title="¿A quién dirigirme?" icon="fas fa-at" />
          <span className="WhoToContact-description">
            El equipo de EFPA España está a vuestra disposición para aclarar
            cualquier duda o consulta. ¡Gracias por confiar en nosotros!
          </span>
          <div className="WhoToContact-wrapper">
            <div className="row efpa-row">{renderContacts()}</div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
