import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

export default function DelegateCard(props) {
  const { className, image, employment, name, phone, link, email } = props

  const renderImage = () => {
    if (!image) {
      return (
        <img className="DelegateCard-image" src="/imgs/delegates/delegate-default.jpg" alt="delegate" />
      )
    }
    return <img className="DelegateCard-image" src={image} alt="delegate" />
  }

  return (
    <div className={`DelegateCard ${className}`}>
      {renderImage()}
      <p className="DelegateCard-employment">{employment}</p>
      <p className="DelegateCard-name">{name}</p>
      <span className="DelegateCard-phone">{phone}</span>
      <Link className="DelegateCard-link" to={link}>
        {email}
      </Link>
    </div>
  )
}

DelegateCard.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  employment: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  link: PropTypes.string,
  email: PropTypes.string,
}

DelegateCard.defaultProps = {
  className: "",
  employment: "",
  name: "",
  phone: "",
  link: "",
  email: "",
}
